@import "../bower_components/normalize.css/normalize";
@import "bootstrap/bootstrap";


.row{
  &:after, &:before{
    display: inline-block;
  }
}

.clear{
  clear: both;
}
*{
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: 'Open Sans', sans-serif;
}
a,button{
  &:hover{
    text-decoration: none;
  }
}
input:focus{
  &::placeholder{
    opacity: 0;
  }
}
button:focus,button:active,input:focus,input:active{
  outline: none!important;
}
a.arrows{
  &:hover{
    cursor: pointer;
    color: #fff!important;
  }
}
.slick-slide:focus{
  outline: none!important;
}
.container{
  max-width: 1400px;
  padding-left: 30px;
  padding-right: 30px;
}
button.slick-arrow{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  opacity: 0;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background: #333;
}
body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #57B9E8;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}

.fatherBlock{
  position: relative;
  overflow: hidden;
  width: 100%;
}
body{
  padding-top: 121px;
}
header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background: #fff;
  .container{
    padding-top: 18px;
    padding-bottom: 18px;
    ul.currencies{
      display: table;
      float: right;
      margin: 0;
      li{
        display: inline-table;
        margin: 0 0 0 30px;
        position: relative;
        padding-left: 35px;
        span{
          display: table;
          margin: 0 auto;
          color: #ccc;
          font-weight: 200;
          font-size: 12px;
        }
        p{
          margin: 0;
        }
        .imgBlock{
          position: absolute;
          left: 0;
          bottom: 2px;
          width: 26px;
          height: 26px;
          text-align: center;
          display: table;
          border-radius: 50%;
          border: 1px solid #555;
          img{
            display: table;
            height: 13px;
            margin: 5px auto 0;
          }
        }
      }
    }
  }
  .blue{
    background: #152848 ;
    .container{
      padding-top: 0;
      padding-bottom: 0;
      ul.menu{
        display: table;
        margin: 0;
        li{
          display: inline-table;
          margin-right: 28px;
          a{
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            display: table;
            padding: 14px 0 15px;
            position: relative;
            &:before{
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              background: #57B9E8;
              opacity: 0;
              transition: .2s linear;
            }
            &:hover{
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      a.searchButton{
        position: absolute;
        right: 15px;
        top: 0;
        height: 100%;
        width: auto;
        border-left: 1px solid rgba(255, 255, 255, 0.15);
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        padding: 12px 25px;
      }
    }
  }
}

.firstBlock{
  padding-top: 60px;
  padding-bottom: 60px;
  .bigNew{
    display: table;
    width: 100%;
    position: relative;
    img.big{
      display: table;
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
    p.tag{
      display: table;
      color: #FF0000;
      margin: 20px 0 15px;
      font-size: 14px;
      span{
        display: inline;
        color: #333;
        margin-left: 20px;
      }
    }
    p.name{
      display: table;
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 18px;
    }
    p.descr{
      display: block;
      line-height: 1.8;
      font-weight: 300;
      width: 90%;
      margin-bottom: 30px;
      height: 125px;
      overflow: hidden;
    }
    ul.views{
      display: table;
      margin: 0;
      li{
        display: inline-table;
        margin: 0 10px 0 0;
        img{
          margin-right: 8px;
          height: 12px;
        }
      }
    }
  }
  .newsBlock{
    display: table;
    width: 100%;
    border: 1px solid #DFDFDF;
    padding: 30px 35px;
    p.bigName{
      display: table;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .block{
      display: table;
      position: relative;
      border-bottom: 2px solid #E7E7E7;
      width: 100%;
      padding: 20px 0 25px;
      .dateBlock{
        display: inline-table;
        width: 30%;
        p{
          display: table;
          margin: 0;
          &.time{
            font-weight: 700;
          }
        }
      }
      .content{
        display: inline-table;
        width: 69%;
        position: relative;
        p.name{
          display: table;
          width: 80%;
        }
      }
      ul.views{
        display: table;
        margin: 0;
        li{
          display: inline-table;
          margin: 0;
          img{
            margin-right: 6px;
            height: 10px;
          }
        }
      }
      a.share{
        position: absolute;
        right: 0;
        bottom: -3px;
        color: #57B9E8;
        font-size: 30px;
        letter-spacing: -0.5px;
      }
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  .newSeven{
    display: block;
    width: 100%;
    position: relative;
    background: linear-gradient(138.41deg, #163262 2.41%, #04204E 98.13%);
    padding: 40px 20px;
    &:before{
      content: '';
      position: absolute;
      width: 95%;
      height: 94%;
      left: 2.5%;
      border: 1px solid rgba(255, 255, 255, 0.17);
      display: table;
      top: 3%;
    }
    img{
      display: table;
      margin: 40px auto 60px;
      width: 60%;
    }
    p.name{
      display: table;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      margin: 0 auto 10px;
      width: 90%;
      color: #fff;
    }
    p.descr{
      display: table;
      text-align: center;
      font-weight: 300;
      opacity: 0.7;
      color: #fff;
      width: 100%;
      font-size: 14px;
      margin-bottom: 40px;
      a{
        display: inline;
        font-size: 14px;
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .miniBlockRekl{
    display: table;
    margin-top: 25px;
    width: 100%;
    a{
      display: table;
      width: 100%;
    }
    img{
      display: table;
      width: 100%;
      height: 280px;
      object-fit: cover;
    }
  }
}
.secondBlock{
  padding-top: 60px;
  padding-bottom: 40px;
  background: #F9F9FA;
  .inBlock{
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    transition: .2s linear;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 63.19%);
    }
    img.bigImg{
      display: table;
      width: 100%;
      height: 334px;
      object-fit: cover;
      object-position: center;
    }
    .content{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 25px 25px;
      p.name{
        display: table;
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        width: 80%;
        margin-bottom: 20px;
      }
      ul.views{
        display: inline-table;
        margin: 0;
        opacity: 0.6;
        li{
          display: inline-table;
          margin: 0 10px 0 0;
          color: #fff;
          img{
            margin-right: 8px;
            height: 12px;
          }
          span{
            font-weight: 300;
            font-size: 12px;
          }
        }
      }

      p.date{
        display: inline-table;
        margin: 0 15px;
        font-weight: 300;
        font-size: 12px;
        color: #fff;
        opacity: 0.6;
      }
      a.share{
        position: absolute;
        right: 25px;
        bottom: 18px;
        color: #fff;
        font-size: 30px;
        letter-spacing: -0.5px;
      }
    }
  }
  .miniBlockFat{
    display: table;
    width: 100%;
    background: #fff;
    padding: 10px 25px;
    transition: .2s linear;
    box-shadow: 0 6px 13px rgba(0, 0, 0, 0.05);
    &:hover{
      box-shadow: 0 6px 13px rgba(0, 0, 0, 0.10);
    }
    .miniBlock{
      display: table;
      width: 100%;
      padding: 25px 0 20px;
      position: relative;
      p.link{
        display: table;
        font-size: 16px;
        color: #57B9E8;
        font-weight: 300;
        text-transform: uppercase;
        span{
          display: inline;
          color: #333;
          text-transform: initial;
          font-size: 13px;
          margin-left: 15px;
        }
      }
      p.name{
        display: table;
        font-weight: 600;
        font-size: 16px;
        width: 85%;
        margin-bottom: 18px;
      }
      ul.views{
        display: table;
        margin: 0;
        li{
          display: inline-table;
          margin: 0 10px 0 0;
          img{
            margin-right: 8px;
            height: 12px;
            transition: .2s linear;
          }
          span{
            font-weight: 300;
            font-size: 12px;
            color: #ccc;
            transition: .2s linear;
          }
        }
      }
      a.share{
        position: absolute;
        right: 0;
        bottom: 18px;
        color: #57B9E8;
        font-size: 30px;
        letter-spacing: -0.5px;
      }
      &:first-child{
        border-bottom: 2px solid #E7E7E7;
      }
      &:hover{
        ul.views{
          display: table;
          margin: 0;
          li{
            display: inline-table;
            margin: 0 10px 0 0;
            img{
              margin-right: 8px;
              height: 12px;
              filter: brightness(0.5);
              transition: .2s linear;
            }
            span{
              font-weight: 300;
              font-size: 12px;
              color: #000;
              transition: .2s linear;
            }
          }
        }
      }
    }
  }
}

.thirdBlock{
  .container{
    padding-top: 60px;
    padding-bottom: 60px;
    ul.tabsMenu{
      display: table;
      margin: 0;
      width: 100%;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 1px;
        background: #ECECEC;
      }
      li{
        display: inline-table;
        margin-right: 28px;
        a{
          color: #333;
          font-weight: 700;
          font-size: 14px;
          display: table;
          padding: 14px 0 15px;
          position: relative;
          &:before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: #57B9E8;
            opacity: 0;
            transition: .2s linear;
          }
          &:hover{
            &:before{
              opacity: 1;
            }
          }
        }
      }
    }
    .blockNewFat{
      position: relative;
      width: 100%;
      .blockNew{
        display: table;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #ECECEC;
        img.bigImg{
          display: inline-table;
          width: 25%;
          height: 170px;
          object-fit: cover;
          vertical-align: top;
        }
        .content{
          display: inline-table;
          width: 74%;
          padding: 0 35px;
          vertical-align: top;
          position: relative;
          p.link{
            display: table;
            font-size: 14px;
            color: #57B9E8;
            font-weight: 300;
            text-transform: uppercase;
            span{
              display: inline;
              color: #333;
              text-transform: initial;
              font-size: 13px;
              margin-left: 15px;
            }
          }
          p.name{
            display: block;
            font-weight: 600;
            font-size: 14px;
            width: 85%;
            height: 40px;
            overflow: hidden;
            color: #000;
            margin-bottom: 18px;
          }
          p.descr{
            display: block;
            width: 80%;
            height: 40px;
            font-weight: 300;
            overflow: hidden;
          }
          ul.views{
            display: table;
            margin: 0;
            li{
              display: inline-table;
              margin: 0 10px 0 0;
              img{
                margin-right: 8px;
                height: 12px;
                filter: brightness(0.9);
                transition: .2s linear;
              }
              span{
                font-weight: 300;
                font-size: 12px;
                color: #263238;
                transition: .2s linear;
              }
            }
          }
          a.share{
            position: absolute;
            right: 0;
            bottom: -3px;
            color: #57B9E8;
            font-size: 30px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .openAll{
      display: table;
      margin: 30px auto 0;
      padding: 12px 42px;
      color: #fff;
      background: #0091EA;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      span{
        font-size: 16px;
        font-weight: 500;
        margin-right: 15px;
      }
    }
    .rightNewsBlock{
      padding-left: 30px;
      position: relative;
      overflow: hidden;
      &:before{
        content: '';
        position: absolute;
        bottom: 74px;
        right: -5px;
        width: 100%;
        height: 100%;
        background: #F9F9FA;
        z-index: 0;
      }
      p.bigName{
        font-weight: 700;
        font-size: 18px;
        display: table;
        padding: 24px 0 14px;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
      }
      .rightBlock{
        display: table;
        width: 100%;
        padding: 20px 0 30px;
        position: relative;
        z-index: 1;
        p.link{
          display: table;
          font-size: 14px;
          color: #57B9E8;
          font-weight: 300;
          span{
            display: inline;
            color: #333;
            text-transform: initial;
            font-size: 13px;
            margin-left: 15px;
          }
        }
        p.name{
          display: block;
          font-weight: 600;
          font-size: 14px;
          width: 85%;
          max-height: 40px;
          overflow: hidden;
          color: #000;
          margin-bottom: 18px;
        }
        ul.views{
          display: table;
          margin: 0;
          li{
            display: inline-table;
            margin: 0 10px 0 0;
            img{
              margin-right: 8px;
              height: 12px;
              filter: brightness(0.9);
              transition: .2s linear;
            }
            span{
              font-weight: 300;
              font-size: 12px;
              color: #263238;
              transition: .2s linear;
            }
          }
        }
      }
    }
  }
}
footer{
  position: relative;
  background: #152848;
  padding-top: 60px;
  .container{
    padding-bottom: 15px;
    img.logo2{
      display: table;
    }
    p.descr{
      display: table;
      color: #fff;
      font-size: 16px;
      margin: 0;
      line-height: 1.6;
    }
    .line{
      display: table;
      width: 100%;
      margin: 30px 0;
      height: 1px;
      background: #fff;
      opacity: 0.1;
      &.secondary{
        margin-bottom: 15px;
      }
    }
    ul.menu{
      display: table;
      margin: 0;
      position: relative;
      li{
        display: inline-table;
        margin-right: 28px;
        a{
          color: #fff;
          font-weight: 600;
          font-size: 14px;
          display: table;
          padding: 0;
          transition: .2s linear;
          position: relative;
          &:hover{
            opacity: 0.6;
          }
        }
      }
      &.newsCompany{
        text-align: right;
        float: right;
        li{
          margin-left: 28px;
          margin-right: 0;
        }
      }
    }
    a.copyr{
      display: table;
      color: #fff;
      margin: 0;
      transition: .2s linear;
      &:hover{
        color: #57B9E8;
      }
    }
  }
}

.inpageNew{
  p.bigLink{
      color: #57B9E8;
    font-size: 18px;
    font-weight: 300;
    span{
      display: inline;
      margin-left: 25px;
      font-weight: 600;
      font-size: 18px;
      color: #263238;
    }
  }
  h1{
    font-size: 28px;
    font-weight: 700;
    display: table;
    margin: 0 0 35px;
    max-width: 60%;
  }
  img.newsImg{
    display: table;
    width: 100%;
    height: 334px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 50px;
  }
  p.desr{
    display: table;
    line-height: 1.8;
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 15px;
  }
  p.text{
    display: table;
    line-height: 1.8;
    font-weight: 400;
    font-size: 16px;
    margin: 0 0 15px;
  }
  .socialBlock{
    display: table;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 80px;
    padding-top: 17px;
    border-top: 1px solid #e7e7e7;
    a.istok{
      display: inline-table;
      font-size: 16px;
      margin: 0;
      span{
        font-weight: 700;
        font-size: 16px;
      }
      &:hover{
        cursor: pointer;
        color: #57B9E8;
      }
    }
    ul{
      display: table;
      float: right;
      margin: 0;
      li{
        display: inline-table;
        vertical-align: middle;
        margin-left: 10px;
        p{
          display: table;
          margin: 0;
          font-size: 16px;
        }
        a{
          display: table;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          transition: .2s linear;
          background: #ebebeb;
          border: none;
          text-align: center;
          color: #333;
          i{
            display: table-cell;
            vertical-align: middle;
            font-size: 10px;
          }
          &:hover{
            color: #fff;
            background: #57B9E8;
          }
        }
      }
    }
  }
  .dopNews{
    display: table;
    width: 100%;
    h2{
      display: table;
      font-weight: 700;
      font-size: 20px;
      margin: 0 0 30px;
      padding-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid #e7e7e7;
    }
    .newBlockBot{
      display: inline-table;
      position: relative;
      width: 33%;
      vertical-align: top;
      &:after{
        content: '';
        position: absolute;
        height: 60%;
        top: 20%;
        border-right: 1px solid #F3F3F3;
        right: 14%;
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
      p.link{
        display: table;
        font-size: 14px;
        color: #57B9E8;
        font-weight: 300;
        span{
          display: inline;
          color: #333;
          text-transform: initial;
          font-size: 13px;
          margin-left: 6px;
        }
      }
      p.name{
        display: block;
        font-weight: 600;
        font-size: 14px;
        width: 85%;
        max-height: 40px;
        overflow: hidden;
        color: #000;
        margin-bottom: 18px;
      }
      ul.views{
        display: table;
        margin: 0;
        li{
          display: inline-table;
          margin: 0 10px 0 0;
          img{
            margin-right: 8px;
            height: 12px;
            filter: brightness(0.9);
            transition: .2s linear;
          }
          span{
            font-weight: 300;
            font-size: 12px;
            color: #263238;
            transition: .2s linear;
          }
        }
      }
    }
  }
  .rightBlockNews{
    display: table;
    width: 100%;
    background: #F9F9FA;
    margin-top: 40px;
    padding: 25px;
    h2{
      display: table;
      font-weight: 700;
      font-size: 18px;
      margin: 0 0 20px;
    }
    .rightMiniBlock{
      display: table;
      width: 100%;
      position: relative;
      padding: 20px 0;
      p.link{
        display: table;
        font-size: 14px;
        color: #57B9E8;
        font-weight: 300;
        margin-bottom: 10px;
        span{
          display: inline;
          color: #333;
          text-transform: initial;
          font-size: 13px;
          margin-left: 6px;
        }
      }
      p.name{
        display: block;
        font-weight: 600;
        font-size: 14px;
        width: 85%;
        max-height: 40px;
        overflow: hidden;
        color: #000;
        margin-bottom: 15px;
      }
      ul.views{
        display: table;
        margin: 0;
        li{
          display: inline-table;
          margin: 0 10px 0 0;
          img{
            margin-right: 8px;
            height: 12px;
            filter: brightness(0.9);
            transition: .2s linear;
          }
          span{
            font-weight: 300;
            font-size: 12px;
            color: #263238;
            transition: .2s linear;
          }
        }
      }
    }
  }
  a.readMore{
    display: table;
    margin: 30px 0 220px;
    padding: 12px 42px;
    color: #fff;
    background: #0091EA;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    i{
      font-size: 16px;
      margin-right: 15px;
    }
  }
}
.newsPage{
  .blockNew{
    p.name{
      max-height: 40px;
      height: auto!important;
    }
  }
}
.container-fluid.firstBlock.inpageNew {
  overflow: hidden;
}
@media(max-width: 1199px){
  header .blue .container ul.menu li {
    margin-right: 18px;
  }

  header .container ul.currencies li {
    margin-left: 25px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .firstBlock .newsBlock {
    padding: 20px 15px;
  }

  .firstBlock .newsBlock .block .content p.name {
    width: 100%;
    font-size: 12px;
  }

  .firstBlock .newsBlock .block .dateBlock p {
    font-size: 12px;
  }

  .firstBlock .newsBlock .block ul.views li span {
    font-size: 12px;
  }

  .firstBlock .newsBlock .block {
    padding: 10px 0 15px;
  }

  .firstBlock .newSeven {
    padding: 45px 10px;
  }

  .firstBlock .newSeven img {
    margin: 20px auto 30px;
  }

  .firstBlock .newSeven p.name {
    font-size: 12px;
  }

  .firstBlock .newSeven p.descr {
    font-size: 10px;
  }

  .firstBlock .newSeven p.descr a {
    font-size: 12px;
  }

  .firstBlock .miniBlockRekl img {
    height: 200px;
  }

  .firstBlock .bigNew img.big {
    height: 200px;
  }

  .firstBlock .bigNew p.name {
    font-size: 16px;
  }

  .firstBlock {
    padding: 40px 15px;
  }

  .secondBlock {
    padding: 40px 15px;
  }

  .secondBlock .inBlock .content p.name {
    font-size: 14px;
    width: 100%;
  }

  .secondBlock .inBlock .content p.date {
    margin: 0;
  }

  .secondBlock .miniBlockFat .miniBlock p.name {
    font-size: 14px;
  }

  .secondBlock .miniBlockFat .miniBlock p.link {
    font-size: 12px;
  }

  .secondBlock .miniBlockFat .miniBlock p.link span {
    font-size: 12px;
    display: table;
    margin: 0;
  }

  .secondBlock .inBlock img.bigImg {
    height: 345px;
  }

  .thirdBlock .container {
    padding: 40px 15px;
  }

  .thirdBlock .container .blockNewFat .blockNew .content p.name {
    width: 100%;
  }

  footer .container p.descr {
    font-size: 14px;
  }

  footer .container ul.menu li {margin: 0 15px 10px 0;}

  footer .container .line.secondary {
    margin-top: 20px;
  }
  .container-fluid.firstBlock.inpageNew {
    overflow: hidden;
  }

  .inpageNew .rightBlockNews .rightMiniBlock p.link span {
    display: table;
    margin: 0;
  }
}
@media(max-width: 769px){
  .secondBlock .inBlock img.bigImg {
    height: 380px;
  }
}
@media(max-width: 678px){
  header a.logo {
    display: table;
    width: 100%;
  }

  header a.logo img {
    display: table;
    height: 24px;
  }

  header .container ul.currencies li p {
    font-size: 12px;
  }

  header .container ul.currencies li span {
    font-size: 10px;
  }

  header .container ul.currencies li .imgBlock {
    height: 20px;
    width: 20px;
  }

  header .container ul.currencies li .imgBlock img {
    height: 10px;
    margin: 4px auto 0;
  }

  header .container ul.currencies li {
    padding-left: 24px;
    margin-left: 10px;
    display: inline-table;
    flex-grow: 1;
  }

  header .container ul.currencies {
    display: table;
  }
  header .container ul.currencies li {
    margin-left: 12px;
  }

  header .container ul.currencies {
    width: 110%;
  }

  .container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .firstBlock {
    overflow: hidden;
  }

  .secondBlock {
    overflow: hidden;
  }

  .container-fluid.thirdBlock {
    overflow: hidden;
  }

  footer {
    overflow: hidden;
  }

  header a.logo {
    margin: 8px 0 0;
  }

  header .container {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  header .blue .container ul.menu {
    display: flex;
    width: 100%;
  }

  header .blue .container ul.menu li {
    display: inline-flex;
    flex-grow: 1;
    margin: 0;
    text-align: center;
  }

  header .blue .container ul.menu li a {
    font-size: 12px;
    width: 100%;
  }

  body {
    padding-top: 92px;
  }

  .firstBlock {
    padding: 10px 0;
  }

  header .blue .container a.searchButton {
    display: none;
  }
  .firstBlock .bigNew img.big {
    height: 220px;
  }

  .firstBlock .bigNew .content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 0 20px 20px;
  }

  .firstBlock .bigNew p.tag {
    display: none;
  }

  .firstBlock .bigNew p.name {
    color: #fff;
  }

  .firstBlock .bigNew p.descr {
    display: none;
  }

  .firstBlock .bigNew ul.views {
    display: inline-table;
  }

  .firstBlock .bigNew ul.views li {
    color: #ccc;
    font-size: 10px;
  }

  .firstBlock .bigNew ul.views li span {
    font-size: 12px;
  }

  .firstBlock .bigNew .content .share {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 25px;
    height: 25px;
    border: 1px solid #eee;
    border-radius: 50%;
    text-align: center;
    display: table;
    color: #fff;
    font-size: 20px;
    line-height: 0.6;
  }

  .firstBlock .bigNew:after {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(360deg,#000 0,rgba(0,0,0,0) 63.19%);}

  .firstBlock .newsBlock {
    border: none;
    padding-top: 10px;
  }

  .firstBlock .newsBlock p.bigName {
    display: none;
  }

  .firstBlock .newsBlock .block .dateBlock {
    bottom: 15px;
    left: 0;
    position: absolute;
  }

  .firstBlock .newsBlock .block .content {
    width: 100%;
  }

  .firstBlock .newsBlock .block .dateBlock p {
    display: inline;
    margin-right: 7px;
  }

  .firstBlock .newsBlock .block .dateBlock p.time {
    font-weight: 300;
  }

  .firstBlock .newsBlock .block a.share {
    display: none;
  }

  .firstBlock .newsBlock .block ul.views {
    float: right;
  }

  .firstBlock .newsBlock .block .content p.name {
    font-weight: 700;
    color: #333;
    margin-bottom: 25px;
  }

  .firstBlock .newsBlock .block:last-child {
    padding-bottom: 15px;
    border-bottom: 2px solid #e7e7e7;
  }

  .firstBlock .newSeven:before {
    width: 99%;
    left: 0.5%;
    height: 96%;
    top: 2%;
  }

  .firstBlock .newSeven {
    padding: 25px 15px;
  }

  .firstBlock .newSeven img {
    display: inline-table;
    width: 40%;
    margin: 0;
    height: auto;
  }

  .firstBlock .newSeven p.name {
    display: inline;
    width: 55%;
    float: right;
    font-size: 11px;
    margin-top: -4px;
    text-align: left;
  }

  .firstBlock .newSeven p.descr {
    float: right;
    width: 55%;
    text-align: left;
    display: inline-table;
    margin: -8px 0 0;
    font-size: 10px;
  }

  .firstBlock .newSeven p.descr a {
    font-size: 10px;
  }

  .firstBlock .miniBlockRekl {
    display: none;
  }

  .secondBlock {
    padding: 10px 0;
  }

  .secondBlock .inBlock img.bigImg {
    height: 200px;
  }

  .secondBlock .inBlock {
    margin-bottom: 10px;
  }

  .secondBlock .inBlock .content p.name {
    font-size: 16px;
  }

  .secondBlock .miniBlockFat .miniBlock p.link span {
    display: inline;
    margin-left: 10px;
    font-size: 11px;
  }

  .secondBlock .miniBlockFat .miniBlock p.link {
    font-size: 14px;
  }

  .secondBlock .miniBlockFat .miniBlock p.name {
    width: 100%;
  }

  .secondBlock .miniBlockFat {
    margin-bottom: 10px;
  }

  .container-fluid.thirdBlock {
    padding: 0;
  }

  .thirdBlock .container {
    padding: 0 10px 20px;
  }

  .thirdBlock .container ul.tabsMenu li {
    display: inline-flex;
    flex-grow: 1;
    margin: 0;
  }

  .thirdBlock .container ul.tabsMenu {
    display: flex;
    width: 100%;
  }

  .thirdBlock .container ul.tabsMenu li a {
    width: 100%;
    text-align: center;
  }

  .thirdBlock .container .blockNewFat .blockNew .content {
    padding: 0 0 0 15px;
    width: 69%;
  }

  .thirdBlock .container .blockNewFat .blockNew .content p.link {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .thirdBlock .container .blockNewFat .blockNew .content p.link span {
    font-size: 10px;
  }

  .thirdBlock .container .blockNewFat .blockNew .content p.name {
    margin-bottom: 8px;
  }

  .thirdBlock .container .blockNewFat .blockNew .content a.share {
    display: none;
  }

  .thirdBlock .container .blockNewFat .blockNew img.bigImg {
    height: 140px;
    width: 30%;
  }

  .thirdBlock .container .openAll {
    line-height: 0;
    font-size: 12px;
    margin: 15px auto;
    padding: 5px 15px;
  }

  .thirdBlock .container .openAll span {
    font-size: 12px;
  }

  footer {
    padding: 24px 0 15px;
  }

  footer .container img.logo2 {
    width: 25%;
  }

  footer .container p.descr {
    display: table;
    width: 100%;
    margin: 15px 0 5px;
    font-size: 11px;
    opacity: 0.8;
    font-weight: 300;
    padding: 10px 0 14px;
    border-bottom: 1px solid #ffffff26;
    border-top: 1px solid #ffffff26;
  }

  footer .container ul.menu.newsCompany {
    display: none;
  }

  footer .container ul.menu {
    position: absolute;
    top: 20px;
    right: 0;
    display: none;
  }

  footer .container .line {
    display: none;
  }

  footer .container a.copyr {
    font-size: 12px;
  }

  footer .container {
    padding-bottom: 0;
  }
  .thirdBlock .container {
    padding-bottom: 0;
  }
  .firstBlock .newsBlock {
    padding-bottom: 10px;
  }

  .firstBlock {
    padding-bottom: 0;
  }
  footer .container ul.menu.mobileMenu {
    position: absolute;
    right: 10px;
    top: 5px;
    width: 66%;
    display: flex!important;
  }

  footer .container ul.menu li a {
    font-size: 11px;
  }

  footer .container ul.menu li {
    display: inline-flex;
    flex-grow: 1;
    margin: 0;
  }
  .container-fluid.firstBlock.inpageNew {
    padding-top: 20px;
  }

  .inpageNew p.bigLink {
    font-size: 14px;
  }

  .inpageNew p.bigLink span {
    font-size: 12px;
  }

  .inpageNew h1 {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .inpageNew img.newsImg {
    height: 180px;
    margin-bottom: 20px;
  }

  .inpageNew p.desr {
    font-size: 14px;
  }

  .inpageNew p.text {
    font-size: 14px;
  }

  .inpageNew .socialBlock {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .inpageNew .socialBlock a.istok {
    font-size: 14px;
  }

  .inpageNew .socialBlock a.istok span {
    font-size: 14px;
  }

  .inpageNew .socialBlock ul li p {
    font-size: 13px;
  }

  .inpageNew .socialBlock ul {
    margin-top: -4px;
  }

  .inpageNew .dopNews {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 120px;
    padding: 0 15px;
  }

  .inpageNew .rightBlockNews {
    display: none;
  }

  .container-fluid.firstBlock.inpageNew .container {
    padding-bottom: 250px;
  }

  .inpageNew .dopNews .newBlockBot {
    width: 50%;
  }

  .inpageNew .dopNews .newBlockBot:last-child {
    display: none;
  }
  .inpageNew .dopNews .newBlockBot:first-child{
    display:none;
  }

  .inpageNew .dopNews .newBlockBot p.link span {
    display: table;
    margin: 0;
  }

  .inpageNew .dopNews h2 {
    margin-bottom: 10px;
  }
  .inpageNew a.readMore {
    margin: 10px auto;
    font-size: 12px;
  }

  .inpageNew a.readMore i {
    font-size: 12px;
  }


  .inpageNew p.desr {
    margin-bottom: 10px;
  }
  .thirdBlock .container .rightNewsBlock {
    display: none;
  }
}